import { createGlobalStyle } from 'styled-components'
import  { globalStyles } from 'twin.macro'

const GlobalStyles = createGlobalStyle(globalStyles, `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
  .call-btn-rounded span {
        position: absolute;
        background-color: #9926f0;
        width: 2.5rem;
        height: 2.5rem;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        border-radius: 50%;
    }
   .blinkbtn {
   animation: blinkbtn 1s linear infinite;
   }
`)

export default GlobalStyles