import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "/assets/img/goa.jpg",
      title: "Pune To Goa",
      description: "Embark on an unforgettable journey from Pune to Goa with our all-inclusive travel package, designed to offer a perfect blend of relaxation, adventure, and cultural experiences. This package ensures a seamless and enjoyable trip, whether you're looking for a tranquil getaway or an action-packed holiday.",
      locationText: "Pune",
      pricingText: "14/KM",
      rating: "4.8",
    },
    {
      imageSrc: "/assets/img/nashik.jpeg",
      title: "Pune To Nashik",
      description: "Experience the beauty and cultural richness of Nashik with our thoughtfully designed travel package from Pune. Whether you're a history enthusiast, nature lover, or wine connoisseur, this package offers a memorable escape with a variety of attractions and activities to explore.",
      locationText: "Pune",
      pricingText: "14/KM",
      rating: 4.9,
    },
    {
      imageSrc: "/assets/img/mahableswar.jpg",
      title: "Pune To Mahabaleshwar",
      description: "Escape to the lush hills and breathtaking views of Mahabaleshwar with our thoughtfully designed travel package from Pune. Perfect for nature enthusiasts, couples, and families, this package offers a serene getaway with a mix of scenic beauty, relaxation, and local experiences.",
      locationText: "Pune",
      pricingText: "14/KM",
      rating: "5.0",
    },
    {
      imageSrc: "/assets/img/malvan.avif",
      title: "Pune To Malvan",
      description: "Discover the charm and beauty of Malvan with our specially designed travel package from Pune. Ideal for those seeking a coastal retreat, this package offers a delightful mix of scenic beaches, cultural experiences, and local cuisine, ensuring a memorable escape to one of Maharashtra’s most picturesque destinations.",
      locationText: "Pune",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },
    {
      imageSrc: "/assets/img/Ganpatipule.jpg",
      title: "Pune To Ganapatipule",
      description: "Embark on a serene journey from Pune to Ganpatipule with our carefully designed travel package. Ideal for those seeking a peaceful retreat combined with spiritual enrichment, this package offers a blend of stunning coastal beauty, sacred temples, and relaxing experiences.",
      locationText: "Pune",
      pricingText: "14/KM",
      rating: 4.5,
    },
    {
      imageSrc: "/assets/img/alibag.jpg",
      title: "Pune To Alibag",
      description: "Experience the serene beauty and relaxing charm of Alibaug with our well-crafted travel package from Pune. Perfect for a coastal getaway, this package offers a blend of beach relaxation, historical exploration, and local cuisine, ensuring a refreshing and enjoyable trip.",
      locationText: "Pune",
      pricingText: "14/KM",
      rating: 4.5,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Pune To Shirdi",
      description: "Embark on a spiritual and serene journey from Pune to Shirdi with our well-curated travel package. Perfect for devotees and travelers seeking peace and inspiration, this package offers a comfortable and enriching experience to visit the revered Sai Baba Temple and explore the spiritual essence of Shirdi.",
      locationText: "Pune",
      pricingText: "14/KM",
      rating: 4.5,
    },
  ]
  const sendWhatsapp= async(title)=>{
    let text="i am looking for more information about your package:"+title;
    window.location.href = "https://api.whatsapp.com/send?phone=917722055354&text="+text; 
  }
  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Our Packages</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton onClick={sendWhatsapp.bind(this,card.title)}>Book Now</PrimaryButton>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
