import React from "react";
import { Button } from 'react-bootstrap';
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Services from "web/features/Services";

import Hero from "web/hero/BackgroundAsImage.js";
import SliderCard from "web/cards/ThreeColSlider.js";

import OurTrackRecord from "web/features/OurTrackRecord.js";

import Cabs from "web/cards/Cabs.js";

import Testimonial from "web/testimonials/Testimonial.js";

import ContactUsForm from "web/forms/ContactUsForm.js";
import FAQ from "web/faqs/faq.js";

//import Hero from "../components/hero/BackgroundAsImage.js";
//import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";

//import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
//import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
//import FAQ from "components/faqs/SimpleWithSideImage.js";
//import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "web/footers/footer.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";

export default () => (
  <section id="home">
    <AnimationRevealPage>
    <section style={{  position: 'absolute',zIndex:99999}}>
      <div style={{position:'fixed',bottom:'100px',right:'20px'}}>
        <div className='col-md-4 col-sm-12 call-btn-rounded ' style={{position:'absolute',bottom:'70px'}}>
          <span className='blinkbtn'>
            <a href="https://api.whatsapp.com/send?phone=917722055354" ><i className="fa-brands fa-whatsapp top-icon" style={{fontSize:'20px'}} aria-hidden="true"> </i></a>
          </span>
              </div>
              <div className='col-md-4 col-sm-12 call-btn-rounded blinkbtn' style={{position:'absolute',bottom:'20px'}}> 
          <span className='blinkbtn'>
            <a href="tel:+91 7722055354">		
              <i className="fas fa-phone-alt top-icon" style={{fontSize:'20px'}} aria-hidden="true"></i>
            </a>
          </span>
              </div>
      </div>
    </section>  
      <Hero />
      <section id="aboutus" >
      <OurTrackRecord />
      </section>
      
      <section id="services" className="services">
        <Services />
      </section>
      
      <section id="packages">
        <SliderCard/>
      </section>
      
      <section id="ourCars">
        <Cabs />
      </section>
      
      <Testimonial
        subheading="Testimonials"
        heading={
          <>
            Our Clients <span tw="text-primary-500">Love Us.</span>
          </>
        }
        description="Here are what some of our amazing customers are saying about our travels."
        testimonials={[
          {
            imageSrc:
              "/assets/img/user.png",
            profileImageSrc:
              "/assets/img/user.png",
            quote:
              "I had an excellent experience with Easy Cabs. The booking process was smooth, and the car was in pristine condition. The staff was friendly and professional. I will definitely use their services again!",
            customerName: "Harshit Rane",
            customerTitle: "Happy Customer"
          },
          {
            imageSrc:
              "/assets/img/user.png",
            profileImageSrc:
              "/assets/img/user.png",
            quote:
              "Great rental experience! The car was clean and well-maintained, and the pick-up and drop-off were efficient. The only minor issue was a small delay in getting the car, but overall, I'm very satisfied.",
            customerName: "Sujata",
            customerTitle: "Happy Customer"
          }
        ]}
        textOnLeft={true}
      />
      <FAQ
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Do you have <span tw="text-primary-500">Questions ?</span>
          </>
        }
      />
      <section id="contactUs">
        <ContactUsForm />
      </section>
      
      <Footer />
      
    </AnimationRevealPage>
  </section>
);
